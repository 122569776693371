 
const Discount  = ({ price, curPrice, refLink}) => {
 


  // return (

  //   <p>  discount ret  {price}  curprice {curPrice}  </p>
  // )
 
  console.log("both are real number return %",  price, curPrice)
 
    let discount = 0.0

  if ( isNaN(price) &&  isNaN(curPrice) ) {

    // console.log("both are real number return %",  price.price, price.curPrice)

      let p1 = parseFloat(price.replace('$',''))
      let p2 = parseFloat(curPrice.replace('$',''))

      discount = 100.0*(p1-p2)/p1
  
       console.log('discount ok ', discount, p1, p2)
 
  }
  else {
    console.log("one of them is Nan -- Dont return %",  price, curPrice)

  }


  // console.log(isNaN(price.price),  isNaN(price.curPrice))
  console.log('discount ok22 ', discount)
  
  if(discount === 0.0) {

    return  <>
                {/* <a class="shopnow" href={price.refLink}
                target="_blank">  <i class="fa fa-shopping-cart"></i> Shop Now</a> */}


    </>
  }
  else {
  
      return (
          <> 
          
          -{discount.toFixed(0)}%
          
          
          {/* {discount.toFixed(0)}% Discount */}
          {/* <br/>
          
          <a class="shopnow" href={price.refLink}
          target="_blank">  <i class="fa fa-shopping-cart"></i> Shop Now</a> */}
        
  

       </> 
        ) 
  }
  
 
   

}

export default Discount