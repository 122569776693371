import "../dsea.css"

function Header() {
 

 return (
 
  <div id="main-title"> 
  <span> 
  <a href="#">  Hot Deals </a>  |  <a href="#">  Coupons </a>   | 
   <a href="#">  Free Stuff  </a>  |  <a href="#">  Deal of the Day  </a>  </span>         
  </div>
 
 )
}

export default Header