 
import '../dsea.css'
 
import Discount from "./Discount";
import Header from "./Header";

const Dbox = ({ id, title, refLink,  imgTxt, imgSRC, desc, price, curPrice, memo, moreInfo   }) => {
 
    // return  (

    // <div class="prodimage"><a href={refLink}>
    // <img src={imgSRC} alt={imgTxt}/> </a>
    //     <span class="curprice"    > {curPrice}  </span>

    //      <span class="perctdown"> <br/>
    //      <div id="dealTitle">
    //     <strong> <span>  <a href={refLink}> {title}</a> </span></strong>        

    //     {/* <p> price {price} curPrice {curPrice} {id}</p>       */}
           
    //     </div>

   
        
    //     <Discount  price={price}  curPrice={curPrice} refLink={refLink}  />

        
        
    //     </span>     

    // </div>  


    //   )


    return (

            <> 
            {/* <Header/> */}
            <div class="dealbox">

            
        
            { console.log('title ', title) }


            {/* <h1>  Deal  {rdata.id} </h1>   */}

        <div class="prodimage"><a href={refLink}>
            <img src={imgSRC} alt={imgTxt}/> </a>
                <span class="curprice"    > {curPrice}  </span>
 
                 <span class="perctdown"> <br/>
       
                <strong> 
 
                <Discount price={price}  curPrice={curPrice} refLink={refLink} />
                
       
                </strong>
                
                </span>     
 
        </div>   

        <div id="dealTitle">
        <strong> <span>  <a href={refLink}> {title}</a> </span></strong>              
           
        </div>

        <div class="dealcontent"> 
        <div class="posttext">
            <ui> <span > {desc} </span>  </ui>
 
            {/* {console.log('memo ', memo)} */}

            <div dangerouslySetInnerHTML={{ __html: moreInfo }} />
            
            {/* <div dangerouslySetInnerHTML={{ __html: "<h1>Hi there!</h1>" }} /> */}
 
        </div> 
        </div>

        <div class="break" > 
        </div>   

        {/* {console.log('data check ', deals[0].title)}

        {console.log('data map ', rdata.title)}     */}

        </div>   
        </>


  )
}

export default Dbox

