import "../dsea.css"

function SideFeeds() {
 

 return (
 
 
 <div id="col2">
                    
<div class="cpnfrontleft" >
    <h2><span>Trending Now </span></h2>

    <span > Apple <br/>  ASICS    
    <br/> Amazon <br/> Kroger <br/> Macys <br/> Kohls <br/> Walmart
    </span>
    </div>

</div>
 
 
 )
}

export default SideFeeds


