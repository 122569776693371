
import DataLoader from "./DataLoader";
import Footer from "./components/Footer";
import SideFeeds from "./components/SideFeeds";  

import './dsea.css'
import Header from "./components/Header";


const App = () => {


    return (

 
        < >
            
        <div id="header" > 
                
            

        <div class="container"  style= {{ color: 'orange', padding: "10px 10px 15px 15px"}}>

            {/* <h2>  Deal2Check  - header placeholder ***  logo navi search  </h2> */}
            
            
            {/* <div id="profile">  
            <a href= {"#"} >Login</a> or <a href= {"#"} >Register</a>,
            <a style={{color:"#E47911"}} href="#">Deal Alert</a>
            </div>  */}

            <div id="logo" style={{top:"5px" , bottom:"20px"}}>
                <a href={"./deal2check.png"} target={"_top"}>
                <img src={"./deal2check.png"} alt={"Deal2Check"}/>  </a>
            </div>


            <div id="mainsearch"  >
            <div>
        
            <form method={"get"} action={"/search"} target={"_top"} name="SearchForm">  <label >    
            <input type="text" name="q"  id="q"  placeholder="Search in Deals" /> </label>
            <input type="submit"  value="Search" title="Go" id="go"/>
            <input type="hidden" name="search_mode"  value="Deals"/>
            </form>


            </div>    
            </div>  


        </div> {/* container div */ } 

 

        <Header/>

        </div> {/* headers div */ }



        <DataLoader/>

        <SideFeeds/>
        <Footer/>



        </>  /* this can be just one return element and not the class */



    );
};

export default App;
