 
import React, { useEffect, useState } from "react";
import axios from "axios";

import DealList from "./components/DealList";
import Pagination from "./components/Pagination";


const url2 = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"

const url =  "DB2.json"

const DataLoader = () => {

const [coinsData, setCoinsData] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage, setPostsPerPage] = useState(8);

const getListing = async () => {
           
        await axios.get(url).then((res) => {
          
          console.log('json data ',res.data)
          console.log('finalURL', url)
          setCoinsData(res.data);

      })
      .catch(err => {
          console.log("error while loading ", err)
      })
    
  }


useEffect(() => {

    getListing() 
        
  }, []);
  

const lastPostIndex = currentPage * postsPerPage;
const firstPostIndex = lastPostIndex - postsPerPage;
const currentPosts = coinsData.slice(firstPostIndex, lastPostIndex);

return (

    <> 
     { <DealList coinsData={currentPosts} /> } 

            <Pagination
                totalPosts={coinsData.length}
              
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            /> 

</>
            )

}


export default DataLoader;