import React from "react";

import { Fragment } from "react";

import CryptoCard from "./DealBox";

import "./CryptoList.css";


import Dbox from "./Dbox";

const DealList = ({ coinsData }) => {
    
    return (
        <div className='crypto_list'>
            {coinsData.map((coin, index) => {
                return (
                    
                <Fragment> 
    
                <div id="col1">
    

                    <div id="fp-deals">
                    <Dbox
                        key={index}
                        id={coin.id}
                        title={coin.title}
                        refLink={coin.refLink}
                        imgTxt={coin.imgTxt}
                        imgSRC={coin.imgSRC}
                        desc={coin.desc}
                        price={coin.price}  
                        curPrice={coin.curPrice} 
                        memo={coin.memo}
                        moreInfo= {coin.moreInfo}
                     />
                   </div>


                </div> 
                    
                </Fragment>



                );
            })}
        </div>
    );
    
    
    
    // return (
    //     <div className='crypto_list'>
    //         {coinsData.map((coin, index) => {
    //             return (
    //                 <CryptoCard
    //                     key={index}
    //                     image={coin.imgSRC}
    //                     // name={coin.name}
    //                     price={coin.price}


    //                 />
    //             );
    //         })}
    //     </div>
    // );



};

export default DealList;
