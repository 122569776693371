
function Footer() {

       let curPrice = '$34.04'
       let p11 = '?'
               let p1 = parseFloat(curPrice.replace('$',''))

                if ( p1 !==0) {
                  p11='Isnumber'
                }

    return (
        <p style={{ textAlign: "center" }}>
          <b> 

            Deal2Check.com 
          </b>
        </p>
    )
}

export default Footer